.MuiStepIcon-text {
  fill: #666666 !important;
}
.MuiStepIcon-root {
  color: #ffffff !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #c67dff !important;
}
.MuiStepIcon-root.MuiStepIcon-active > .MuiStepIcon-text {
  fill: #ffffff !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #c67dff !important;
}
.MuiStepIcon-root.MuiStepIcon-completed > .MuiStepIcon-text {
  fill: #ffffff !important;
}

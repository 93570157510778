.scheduled-card {
  background-color: #eeeeef;
  border-radius: 1rem;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
  padding: 1rem 2.5rem;
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.scheduled-card .date-time {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  text-align: center;
  line-height: 1;
  margin-top: 1rem;
}

.scheduled-card .date-time > .date {
  width: 40%;
  margin-right: 20%;
  position: relative;
}

.scheduled-card .date-time > .date:after {
  content: "at";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(120%, -50%);
  -webkit-transform: translate(120%, -50%);
  -moz-transform: translate(120%, -50%);
  -o-transform: translate(120%, -50%);
  -ms-transform: translate(120%, -50%);
  border-radius: 50%;
  background-image: linear-gradient(to left, #c254f5, #842dda);
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #fff;
}

.scheduled-card .date-time > .date:before {
  content: "";
  width: 0.1rem;
  height: 100%;
  right: -23px;
  top: 0;
  bottom: 0;
  background-color: #842dda;
  position: absolute;
}

.scheduled-card .date-time > .time {
  width: 40%;
}

.scheduled-card .date-time .month,
.scheduled-card .date-time .time-text,
.scheduled-card .date-time .year,
.scheduled-card .date-time .am {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.scheduled-card .date-time .number {
  font-size: 2.5rem;
  font-weight: 600;
  position: relative;
}

.scheduled-card .date-time .number-minute {
  font-size: 2.5rem;
  font-weight: 600;
  position: relative;
}

.scheduled-card .date-time .number > sup {
  font-size: 1rem;
  top: 0rem;
  position: absolute;
}

.scheduled-card .date-time .time-text {
  opacity: 0.3;
}

.scheduled-card .date-time .year {
  opacity: 0.3;
}

.mocktest-btn {
  border: 0;
  font-size: 1.5rem;
  color: #fff;
  vertical-align: middle;
  padding: 0.5rem 2.5rem !important;
  border-radius: 3rem !important;
  box-shadow: 0 0.1rem 0.25rem 0 rgba(91, 22, 170, 0.3);
  background-image: linear-gradient(to left, #c254f5, #842dda);
  outline: 0;
  outline: none;
  min-width: 21rem;
}

.mocktest-btn:hover,
.mocktest-btn:visited,
.mocktest-btn:focus,
.mocktest-btn:active {
  color: #fff;
  background-image: linear-gradient(to left, #c254f5, #842dda);
  outline: none;
}

.list {
  padding: 0;
  margin-bottom: 2rem;
  list-style-type: none;
  font-size: 1.3rem;
}

.list > li:before {
  top: 0.9rem;
  margin-top: -0.25rem;
}

.list > li {
  position: relative;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  list-style-type: none;
  padding-inline-start: none;
}

.list > li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-image: linear-gradient(to left, #c254f5, #842dda);
  border-radius: 50%;
  top: 0.7rem;
  margin-top: -0.25rem;
}
.section {
  margin-bottom: 3.5rem;
  border-bottom: 0.2rem solid #d8d8d8;
  padding-bottom: 3rem;
}

.section:last-child {
  border-bottom: 0;
}

.bnat-video-thumbnail {
  display: block;
  position: relative;
  border-top: 0.5rem solid #efefef;
  border-bottom: 0.5rem solid #efefef;
  cursor: pointer;
}

.bnat-video-thumbnail ~ p {
  margin-bottom: 1rem;
  /* font-size: 1rem; */
  font-weight: 600;
  margin-top: 0.2rem;
}

.bnat-video-thumbnail-play-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -28px;
  margin-left: -28px;
  z-index: 0;
}

.bnat-video-thumbnail-img {
  width: 100%;
  height: auto;
}

.bnat-video-thumbnail:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.layout-menu-content {
  position: absolute;
  left: 4%;
  /* padding-left: 10%; */
}

.layout-menu-content > .nav {
  position: fixed;
  box-shadow: 0rem 0.3rem 0.55rem rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  line-height: 3;
  list-style-type: none;
  padding: 0;
}

.layout-menu-content > .nav > li {
  padding: 0rem;
  margin: 0;
  border-bottom: 1px solid #eeeeef;
}

.layout-menu-content > .nav > li:last-child {
  border-bottom: 0;
}

.layout-menu-content > .nav > li.topics {
  background-image: linear-gradient(to left, #c254f5, #842dda);
  font-weight: 600;
  color: #fff;
}

.layout-menu-content > .nav > li.topics > a {
  color: #fff;
  cursor: default;
}

.layout-menu-content > .nav > li.topics > a:hover,
.layout-menu-content > .nav > li.topics > a:focus {
  background-color: transparent;
}

.layout-menu-content > .nav > li > a {
  color: #6d7278;
  border-radius: 0;
  font-size: 1rem;
  padding: 1rem 1rem;
}

.layout-menu-content > .nav > li.active {
  background-color: #ededee;
}

.layout-menu-content > .nav > li.active > a {
  color: #000000;
  border-radius: 0;
  font-size: 1rem;
  background-color: transparent;
}
a {
  text-decoration: none !important;
}

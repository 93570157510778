.header-container {
  position: sticky;
  width: 100%;
  top: 0;
  width: 100%;
  box-shadow: 3px 3px 5px 2px #96b5d0;
  padding: 15px;
  box-sizing: border-box;
  z-index: 999;
  background-color: white;
}
.logo {
  font-weight: 100;
  font-size: 20px;
}
.text {
  /* border-left: 1px solid gray;
  margin-left: 10px !important;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 100; */
}

.MuiChip-label {
  font-family: "GothamBold";
  min-width: 100px;
  background-image: linear-gradient(to right, #9752ff, #5e93ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.MuiChip-avatar {
  width: 18px !important;
  height: 18px !important;
}

@media only screen and (min-width: 1000px) {
  .MuiChip-label {
    min-width: 130px;
  }
  .MuiChip-avatar {
    width: 24px !important;
    height: 24px !important;
  }
}

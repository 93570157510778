.App {
  text-align: center;
  width: 100%;
  height: 100%;
}
.comprehensiveNotes {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.TestPage {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}
.MockDetailPage,
.BnatDetailPage {
  width: 100%;
  min-height: 100%;
}
.ErrorPage,
.QuizPage,
.QuizResult {
  width: 100%;
  min-height: 100%;
}

.ErrorPage {
  background-color: #cee1f1;
}

@font-face {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/GothamSSm-Book.otf");
    src: local("Gotham"), local("Gotham"),
        url("./assets/fonts/GothamSSm-Book.otf") format("opentype"); /* IE6-IE8 */
    font-display: swap;
}

@font-face {
    font-family: "GothamBold";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/GothamSSm-Bold.otf");
    src: local("Gotham"), local("Gotham"),
        url("./assets/fonts/GothamSSm-Bold.otf") format("opentype"); /* IE6-IE8 */
    font-display: swap;
}
@font-face {
  font-family: "GothamBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/GothamSSm-Bold.otf");
  src: local("GothamBold"), local("GothamBold"),
    url("./assets/fonts/GothamSSm-Bold.otf") format("opentype"); /* IE6-IE8 */
  font-display: swap;
}
html {
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    /* font-family: "Gotham"; */
    height: 100%;
    width: 100%;
    touch-action: manipulation;
    -webkit-user-select: none;
    background-color: rgb(240, 245, 249);
}

#root {
    height: inherit;
    width: inherit;
}
* {
    font-family: "Gotham";
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
